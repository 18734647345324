import React, { useContext, useEffect, useState } from "react";
import { submitFine } from "../actions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "../CommonComponents/TextField";
import Dropdown from "../CommonComponents/Dropdown";
import Upload from "../CommonComponents/Upload";
import Modal from "@mui/material/Modal";
import FineManagementSubModal from "./FineManagementSubModal";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../context/authcontext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  height: "80vh",
  bgcolor: "#FFF",
  maxWidth: "800px",
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const FineManagementModal = ({ rowData, setOpen }) => {
  const [pic1, setPic1] = useState(null);
  const [pic2, setPic2] = useState(null);

  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [fineData, setFineData] = useState({});

  const handleChange = (e) => {
    setFineData({ ...fineData, [e.target.name]: e.target.value });
  };
  const clearAll = () => {
    setFineData({
      vendor_name: "",
      mobile_no: "",
      signature_img_url: "",
      aadhar_no: "",
      weight: "",
      tanch: "",
      fine: "",
      type: "Gold",
      fine_deposited: "",
      fine_remaining: "",
      lab_charge: "",
      lab_quantity: "",
      ref_name: "",
      description: "",
      entry_date: "",
      item_name: "",
      item_img_url: "",
      delievery_date: "",
    });
    setPic1(null);
    setPic2(null);
  };

  const picUpload1 = (e) => {
    const [file] = e.target.files;
    // console.log("pic uploading", file);
    setPic1(URL.createObjectURL(file));
    setFineData({
      ...fineData,
      signature_img_url: file,
    });
    // setFile(file);
  };

  const picUpload2 = (e) => {
    const [file] = e.target.files;

    setPic2(URL.createObjectURL(file));
    setFineData({
      ...fineData,
      item_img_url: file,
    });
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    Object.keys(fineData).forEach((key) => {
      // console.log(key, fineData[key], "value ,key");
      formData.append(key, fineData[key]);
    });
    formData.append("id", rowData?.id);

    let apiLink =
      `${process.env.REACT_APP_SERVER_HOST}` + "api/accounts/addfinedetails";
    if (rowData?.id) {
      apiLink =
        `${process.env.REACT_APP_SERVER_HOST}` +
        "api/accounts/updatefinedetails";
    }
    try {
      const addApi = await axios.post(apiLink, formData);
      setLoading(true);
      if (addApi.data.code === 200) {
        toast.success(addApi.data.message);
        setFetchAgain((prev) => !prev);
        setOpen(false);
        clearAll();
      } else {
        toast.error(addApi.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "error in adding fine ");
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rowData) {
      setFineData({
        vendor_name: rowData?.vendor_name,
        mobile_no: rowData?.mobile_no,
        signature_img_url: rowData?.signature_img_url,
        aadhar_no: rowData?.aadhar_no,
        weight: rowData?.weight,
        tanch: rowData?.tanch,
        fine: rowData?.fine,
        type: rowData?.type,
        fine_deposited: rowData?.fine_deposited,
        fine_remaining: rowData?.fine_remaining,
        lab_charge: rowData?.lab_charge,
        lab_quantity: rowData?.lab_quantity,
        ref_name: rowData?.ref_name,
        description: rowData?.description,
        entry_date: rowData?.entry_date,
        item_name: rowData?.item_name,
        item_img_url: rowData?.item_img_url,
        delievery_date: rowData?.delievery_date,
      });
    } else {
      clearAll();
    }
  }, [rowData]);

  useEffect(() => {
    if (fineData?.weight && fineData?.tanch) {
      let cal_fine =
        (parseFloat(fineData?.weight) * parseFloat(fineData?.tanch)) / 100;
      // console.log('cal_fine',cal_fine);
      setFineData({ ...fineData, fine: cal_fine });
    }
  }, [fineData?.weight, fineData?.tanch]);

  useEffect(() => {
    if (fineData?.fine_deposited && fineData?.fine) {
      let remaining_fine =
        parseFloat(fineData?.fine) - parseFloat(fineData?.fine_deposited);
      setFineData({ ...fineData, fine_remaining: remaining_fine });
    }
  }, [fineData?.fine_deposited, fineData?.fine]);

  useEffect(() => {
    if (fineData?.lab_charge && fineData?.lab_quantity) {
      let total_laber_charge =
        parseFloat(fineData?.lab_charge) * parseInt(fineData?.lab_quantity);
      setFineData({ ...fineData, ["total_lab_charge"]: total_laber_charge });
    }
  }, [fineData?.lab_charge, fineData?.lab_quantity]);
  return (
    <Box sx={style} className="!w-[90%] sm:!w-[80%] lg:!w-full">
      <div className="flex flex-col items-center gap-4 sm:p-[32px] p-4">
        <h1 className="text-[18px] ">Fine Management </h1>
        <div className="flex flex-col gap-[8px] capitalize">
          <div>
            <div className="formLayout">
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Vendor Name"
                  placeholder="Eg Amit "
                  type="text"
                  name="vendor_name"
                  value={fineData?.vendor_name}
                  onChange={handleChange}
                />
                <TextField
                  label="Mobile No"
                  placeholder="number"
                  type="text"
                  name="mobile_no"
                  value={fineData?.mobile_no}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    if (value.length <= 10) {
                      setFineData({
                        ...fineData,
                        [e.target.name]: value,
                      });
                    }
                  }}
                  inputProps={{
                    maxLength: 10,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <Upload
                  label="Upload signature"
                  type="file"
                  name="signature_img_url"
                  handleChnage={picUpload1}
                />
              </div>
              <div className="flex justify-end w-full rounded">
                {pic1 ? (
                  <img src={pic1} className="size-12" />
                ) : rowData?.signature_img_url ? (
                  <img
                    className="size-10"
                    src={`http://localhost:8800/imagePath/${rowData?.signature_img_url
                      .split("/")
                      .slice(5)
                      .join("/")}`}
                    alt="logo"
                  />
                ) : (
                  <p>Upload Image</p>
                )}
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Aadhar No"
                  placeholder="aadhar no"
                  type="text"
                  name="aadhar_no"
                  value={fineData?.aadhar_no}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    if (value.length <= 12) {
                      setFineData({
                        ...fineData,
                        [e.target.name]: value,
                      });
                    }
                  }}
                  inputProps={{
                    maxLength: 12, // Enforce the maximum length in the input field
                    pattern: "[0-9]*", // Restrict to numeric values
                    inputMode: "numeric", // Suggest numeric keyboard on mobile devices
                  }}
                />
                <TextField
                  label="Refrence"
                  placeholder="Refrence name "
                  type="text"
                  name="ref_name"
                  value={fineData?.ref_name}
                  onChange={handleChange}
                />
              </div>
              <div className="modalFormDivs">
                <TextField
                  label="Description"
                  placeholder="Enter Description"
                  type="text"
                  name="description"
                  value={fineData?.description}
                  onChange={handleChange}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Weight"
                  placeholder="weight"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");

                    setFineData({
                      ...fineData,
                      [e.target.name]: value,
                    });
                  }}
                  inputProps={{
                    // maxLength: 12,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  name="weight"
                  value={fineData?.weight}
                  // onChange={handleChange}
                />
                <TextField
                  label="Tanch"
                  placeholder="tanch "
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");

                    setFineData({
                      ...fineData,
                      [e.target.name]: value,
                    });
                  }}
                  inputProps={{
                    // maxLength: 12,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  name="tanch"
                  value={fineData?.tanch}
                  // onChange={handleChange}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Fine"
                  // placeholder="weight"
                  type="number"
                  name="fine"
                  value={fineData?.fine}
                  onChange={handleChange}
                  editable={false}
                />
                <Dropdown
                  value={fineData.type}
                  handleChnage={handleChange}
                  label="Select Category"
                  name={"type"}
                  optionarr={["gold", "silver", "other"]}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Fine Deposited"
                  placeholder="fine_deposited"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");

                    setFineData({
                      ...fineData,
                      [e.target.name]: value,
                    });
                  }}
                  inputProps={{
                    // maxLength: 12,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  name="fine_deposited"
                  value={fineData?.fine_deposited}
                  // onChange={handleChange}
                />
                <TextField
                  label="Remaining Fine"
                  placeholder="remaining fine "
                  type="number"
                  name="fine_remaining"
                  value={fineData?.fine_remaining}
                  onChange={handleChange}
                  editable={false}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Labour Charge"
                  placeholder="lab_charge"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");

                    setFineData({
                      ...fineData,
                      [e.target.name]: value,
                    });
                  }}
                  inputProps={{
                    // maxLength: 12,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  name="lab_charge"
                  value={fineData?.lab_charge}
                  // onChange={handleChange}
                />
                <TextField
                  label="Labour Quantity"
                  placeholder="lab_quantity"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");

                    setFineData({
                      ...fineData,
                      [e.target.name]: value,
                    });
                  }}
                  inputProps={{
                    // maxLength: 12,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                  name="lab_quantity"
                  value={fineData?.lab_quantity}
                  // onChange={handleChange}
                  // editable={false}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Total Labour Charge"
                  placeholder="total_lab_charge"
                  type="number"
                  name="total_lab_charge"
                  value={fineData?.total_lab_charge}
                  onChange={handleChange}
                  editable={false}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="Entry_date"
                  placeholder="entry_date"
                  type="date"
                  name="entry_date"
                  value={fineData?.entry_date}
                  onChange={handleChange}
                />
                <TextField
                  label="Item_name"
                  placeholder="item_name"
                  type="text"
                  name="item_name"
                  value={fineData?.item_name}
                  onChange={handleChange}
                  // editable={false}
                />
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <Upload
                  label="Item Image"
                  type="file"
                  name="item_img_url"
                  handleChnage={picUpload2}
                />
              </div>
              <div className="flex justify-end w-full rounded">
                {pic2 ? (
                  <img src={pic2} className="size-12" />
                ) : rowData?.item_img_url ? (
                  <img
                    className="size-10"
                    src={`http://localhost:8800/imagePath/${rowData?.item_img_url
                      .split("/")
                      .slice(5)
                      .join("/")}`}
                    alt="logo"
                  />
                ) : (
                  <p>Upload Image</p>
                )}
              </div>
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  label="delievery_date"
                  placeholder="delievery_date"
                  type="date"
                  name="delievery_date"
                  value={fineData?.delievery_date}
                  onChange={handleChange}
                />
              </div>

              <div className="flex w-full gap-1">
                <button className="discardBtn" onClick={() => setOpen(false)}>
                  Discard
                </button>
                <button className="submitBtn" onClick={handleSubmit}>
                  {rowData ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal open={open} onClose={handleClose}>
        <FineManagementSubModal />
      </Modal> */}
    </Box>
  );
};

export default FineManagementModal;
