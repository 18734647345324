import React, { useState, useEffect, useContext } from "react";
import { submitFine } from "../actions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "../CommonComponents/TextField";
import Dropdown from "../CommonComponents/Dropdown";
import Upload from "../CommonComponents/Upload";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../context/authcontext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  height: "80vh",
  bgcolor: "#FFF",

  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};

const StockManagementModal = ({
  rowData,
  setOpen,
  setTableData,
  isUpdateMode,
}) => {
  const [pic, setPic] = useState(null);
  const { fetchAgain, setFetchAgain } = useContext(AuthContext);

  const [stockData, setStockData] = useState({
    id: "",
    name: "",
    weight: "",
    entry_date: "",
    category: "Gold",
    ornament_category: "",
    ornament_sub_category: "",
    description: "",
    sold_status: 0,
    img_url: "",
    // price: "",
    oldImagePath: "",
  });
  const clearAll = () => {
    setStockData({
      name: "",
      weight: "",
      entry_date: "",
      category: "Gold",
      ornament_category: "",
      ornament_sub_category: "",
      description: "",
      sold_status: 0,
      img_url: "",
      // price: "",
      oldImagePath: "",
    });
    setPic(null);
  };
  const picUpload = (e) => {
    const [file] = e.target.files;
    // console.log("pic uploading", file);
    setPic(URL.createObjectURL(file));
    setStockData({
      ...stockData,
      img_url: file,
    });
    // setFile(file);
  };
  // console.log(rowData, "rowData in stock");
  useEffect(() => {
    if (rowData && isUpdateMode) {
      setStockData({
        id: rowData.id || "",
        name: rowData.name || "",
        weight: rowData.weight || "",
        entry_date: rowData.entry_date || "",
        category: rowData.category || "",
        ornament_category: rowData.ornament_category || "",
        ornament_sub_category: rowData.ornament_sub_category || "",
        description: rowData.description || "",
        sold_status: rowData.sold_status || "",
        // price: rowData.price || "",
        oldImagePath: rowData.oldImagePath || "",
      });
    } else {
      clearAll();
    }
  }, [rowData, isUpdateMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value, "name,value");
    setStockData((prev) => ({ ...prev, [name]: value }));
  };

  // console.log(
  //   "Rendering StockManagementModal with title:",
  //   isUpdateMode ? "Update Stock" : "New Stock"
  // );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    Object.keys(stockData).forEach((key) => {
      formData.append(key, stockData[key]);
    });

    const url = isUpdateMode
      ? `${process.env.REACT_APP_SERVER_HOST}`+"api/stocks/updateitems"
      : `${process.env.REACT_APP_SERVER_HOST}`+"api/stocks/additems";

    try {
      const response = await axios.post(url, formData);
      // console.log("API Response:", response.data);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        // setTableData((prev) => [...prev, response.data.data]);
        setFetchAgain((prev) => !prev);
        setOpen(false);
        clearAll();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error, "error");
      toast.error("An error occurred while processing your request.");
    }
  };

  const handleDiscard = (e) => {
    e.preventDefault();
    setOpen(false);
    clearAll();
  };
  return (
    <Box sx={style} className="!w-[90%] max-w-[800px] lg:!w-full">
      <div className="flex flex-col items-center gap-4 sm:p-[32px] p-4 capitalize">
        <h1 className="text-[18px] ">
          {isUpdateMode ? "Update Stock" : "New Stock"}
        </h1>
        <div className="w-full">
          <form>
            <div className="formLayout">
              <div className="modalFormDivs !flex-col sm:!flex-row">
                <TextField
                  name={"name"}
                  label="Name"
                  placeholder="Ajay Soni"
                  type="text"
                  value={stockData.name}
                  onChange={handleChange}
                />
                <TextField
                  value={stockData.weight}
                  // onChange={handleChange}
                  name={"weight"}
                  label="Weight"
                  placeholder="eg 50g"
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");

                    setStockData({
                      ...stockData,
                      [e.target.name]: value,
                    });
                  }}
                  inputProps={{
                    // maxLength: 12,
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                />
              </div>
              <div className="modalFormDivs">
                {/* <TextField label="Qunatity" placeholder="eg 5" type="number" /> */}
                <Dropdown
                  value={stockData.category}
                  handleChnage={handleChange}
                  label="Select Category"
                  name={"category"}
                  optionarr={["gold", "silver", "other"]}
                />
              </div>
              <div className="modalFormDivs sm:flex-nowrap !flex-col sm:!flex-row">
                <TextField
                  value={stockData.ornament_category}
                  onChange={handleChange}
                  name={"ornament_category"}
                  label="Ornament Category"
                  placeholder="Enter ornament category"
                  type="text"
                />
                <TextField
                  value={stockData.ornament_sub_category}
                  onChange={handleChange}
                  name={"ornament_sub_category"}
                  label="Ornament sub-category"
                  placeholder="Enter ornament sub-category"
                  type="text"
                />
              </div>
              <div className="modalFormDivs">
                <TextField
                  value={stockData.description}
                  onChange={handleChange}
                  name={"description"}
                  label="Description"
                  placeholder="Enter Description"
                  type="text"
                />
              </div>

              <div className="modalFormDivs">
                <TextField
                  value={stockData.entry_date}
                  onChange={handleChange}
                  name={"entry_date"}
                  label="Date"
                  placeholder="Enter Date"
                  type="date"
                />
              </div>
              <div className="modalFormDivs">
                <Upload
                  // value={rowData?.image}
                  // reference={image}
                  name="img_url"
                  label="Upload Image"
                  // placeholder="7485691458"
                  type="file"
                  handleChnage={picUpload}
                />
              </div>
              <div className="flex justify-end w-full rounded">
                {pic ? (
                  <img src={pic} className="size-12" />
                ) : rowData?.img_url ? (
                  <img
                    className="size-10"
                    src={`http://localhost:8800/imagePath/${rowData?.img_url
                      .split("/")
                      .slice(5)
                      .join("/")}`}
                    alt="logo"
                  />
                ) : (
                  <p>Upload Image</p>
                )}
              </div>

              <div className="flex w-full gap-1">
                <button
                  className="discardBtn"
                  onClick={(e) => {
                    handleDiscard(e);
                  }}
                >
                  Discard
                </button>
                <button
                  className="submitBtn"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {isUpdateMode ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Box>
  );
};

export default StockManagementModal;

// { id: 1, name: "image", label: "Image" },
//   { id: 2, name: "name", label: "Name" },
//   { id: 3, name: "weight", label: "Weight" },
//   { id: 4, name: "date", label: "Date" },
//   { id: 5, name: "soldStatus", label: "Sold Status" },
//   { id: 6, name: "category", label: "Category" },
//   { id: 7, name: "ornamentCategory", label: "Ornament Category" },
//   { id: 8, name: "ornamentSubCategory", label: "Ornament Sub Category" },
//   { id: 9, name: "description", label: "Description" },

// const stockData = {
//   name: name?.current?.value,
//   weight: weight?.current?.value,
//   date: date?.current?.value,
//   // soldStatus: soldStatus.current,
//   category: category?.current?.value,
//   ornamentCategory: ornamentCategory?.current?.value,
//   ornamentSubCategory: ornamentSubCategory?.current?.value,
//   description: description?.current?.value,
// };

// console.log(name?.current?.value);
// console.log(rowData, "stockRowData");
// console.log(stockData, "stockData");
{
  /* <Dropdown
value={stockData.ornament_sub_category}
handleChnage={handleChange}
name={"ornament_sub_category"}
label="Select Ornamnets Sub-Category"
optionarr={["Neclace", "ring", "ear rings", "chain"]}
/> */
}
