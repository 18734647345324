import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";
import Stockmanagement from "../StockManagement/Stockmanagement";

const SelectItemPopUp = ({
  setSeletecedItemOpen = () => {},
  setSelectedItem = () => {},
}) => {
  return (
    <div className="bg-white w-[70%] m-auto">
      <div className="">
        <Stockmanagement
          dateFilter={false}
          columnShow={4}
          download={false}
          action={false}
          title={"Select jewellery"}
          selecting={true}
          setSeletecedItemOpen={setSeletecedItemOpen}
          setSelectedItem={setSelectedItem}
          setFilter={true}
        />
      </div>
    </div>
  );
};
export default SelectItemPopUp;
