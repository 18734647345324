import React from "react";

const Upload = ({
  label,
  placeholder,
  type,
  name,
  reference,
  handleChnage = () => {},
}) => {
  return (
    <div className="labelsDivs w-full">
      <label className="formsLables">{label}</label>
      <input
        className="formsInputs"
        type={type}
        placeholder={placeholder}
        name={name}
        ref={reference}
        onChange={handleChnage}
      ></input>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M19 13.5V17.5C19 18.0304 18.7893 18.5391 18.4142 18.9142C18.0391 19.2893 17.5304 19.5 17 19.5H3C2.46957 19.5 1.96086 19.2893 1.58579 18.9142C1.21071 18.5391 1 18.0304 1 17.5V13.5M15 6.5L10 1.5M10 1.5L5 6.5M10 1.5V13.5"
          stroke="#666666"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg> */}
    </div>
  );
};

export default Upload;
