import React from "react";

const TextField = ({
  label,
  placeholder,
  type,
  name,
  reference,
  value,
  editable = true,
  onChange = () => {},
}) => {
  // console.log(typeof editable, "editable for ", name);
  return (
    <div className="labelsDivs w-full">
      <label className="formsLables">{label}</label>
      <input
        className="formsInputs"
        // contentEditable={editable}
        // editable={editable}
        readOnly={editable ? false : true}
        value={value}
        type={type}
        placeholder={placeholder}
        name={name}
        ref={reference}
        onChange={onChange}
      ></input>
    </div>
  );
};

export default TextField;
