import React from "react";

import Box from "@mui/material/Box";
// import TextField from "../CommonComponents/TextField";
// import Dropdown from "../CommonComponents/Dropdown";
// import Upload from "../CommonComponents/Upload";
// import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";

// const data = [
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
//   {
//     id: 1,
//     date: "24 June 2024",
//     paymentType: "Cash",
//     amount: "3208",
//     quantity: "10",
//   },
// ];

// const columns = [
//   { id: 1, name: "date", label: "Date" },
//   { id: 2, name: "paymentType", label: "Payment Type" },
//   { id: 3, name: "amount", label: "Amount" },
//   { id: 4, name: "quantity", label: "Quantity" },
// ];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "80vh",
  bgcolor: "#FFF",

  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const MortagageManagementPopUp = () => {
  return (
    <Box sx={style}>
      <div className="flex flex-col items-center gap-4  justify-center ">
        <h1 className="text-[18px] py-4">Paid history</h1>
        <div className="flex flex-col gap-4 p-6">
          <div className="flex px-[40px] justify-between gap-20 h-[196px] bg-[#F4F4F4]">
            <div className="flex justify-center items-center">
              <img className="w-[180px]" src="/images/test.png" />
            </div>
            <div className="flex justify-start items-center w-[50%] ">
              <div className="flex-nowrap text-nowrap flex flex-col gap-4  ">
                <div className="flex justify-between gap-28">
                  <div>Weight:</div>
                  <div>48g, 20g etc.</div>
                </div>
                <div className="flex justify-between gap-28">
                  <div>Name:</div>
                  <div>Golden set</div>
                </div>
                <div className="flex justify-between gap-28">
                  <div>Date:</div>
                  <div>20 Aug 2022</div>
                </div>
                <div className="flex justify-between gap-28">
                  <div>Name of buyer:</div>
                  <div>Raja ram</div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="w-full h-[1px] bg-black/10"></div> */}

          {/* <div>
            <CustomTableComponent
              //   title="Stock Management"
              data={data.slice(0, 10)}
              columns={columns}
              search={false}
              dateFilter={false}
              columnFilter={false}
              addNew={false}
              download={false}
              completeHeader={false}
              action={false}
              columnShow={4}
              //   Module={StockManagementModal}
              //   PopUp={StockManagementPopUp}
            />
          </div> */}
        </div>
      </div>
    </Box>
  );
};

export default MortagageManagementPopUp;
