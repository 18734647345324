// import * as React from "react";
// // import { LineChart } from "@mui/x-charts/LineChart";
// // import { Doughnut } from "react-chartjs-2";
// // import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Line } from "react-chartjs-2";
// // ChartJS.register(ArcElement, Tooltip, Legend);
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
// } from "chart.js";

// // Register the required components
// ChartJS.register(
//   LineElement,
//   PointElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale
// );

// export default function BasicArea({ data }) {
//   console.log(data, "new dataaaaaaaaaaaaa");

//   const transformedData = React.useMemo(() => {
//     if (!data || data.length === 0) return { xAxisData: [], yAxisData: [] };

//     // Sort the data by date
//     const sortedData = [...data].sort(
//       (a, b) => new Date(a.date) - new Date(b.date)
//     );

//     const xAxisData = sortedData.map((item) => new Date(item.date));
//     const yAxisData = sortedData.map((item) => item.quantity);
//     console.log(xAxisData, "fsddhkjhf");

//     const datasets = [
//       {
//         label: "Quantities",
//         data: yAxisData,
//         fill: true,
//         backgroundColor: ["#FF6384"],
//         borderColor: "rgba(75,192,192,1)",
//       },
//     ];

//     return { xAxisData, yAxisData, datasets };
//   }, [data]);

//   if (!data || data.length === 0) {
//     return <div>No data available</div>;
//   }

//   return (
//     // <LineChart
//     //   xAxis={[
//     //     { data: transformedData.xAxisData, scaleType: "time", label: "Date" },
//     //   ]}
//     //   series={[
//     //     {
//     //       data: transformedData.yAxisData,
//     //       area: true,
//     //       stroke: "url(#gradient)", // Apply the gradient to the line stroke
//     //       fill: "url(#gradient)", // Apply the gradient to the area fill
//     //     },
//     //   ]}
//     //   // width={"100%"}
//     //   // height={300}
//     //   className="w-full"
//     // >
//     //   <defs>
//     //     <linearGradient id="gradient" x1="1" y1="0" x2="0" y2="1">
//     //       <stop offset="0%" stopColor="#C4A845" />
//     //       <stop offset="100%" stopColor="rgba(156, 131, 43, 0.40)" />
//     //     </linearGradient>
//     //   </defs>
//     // </LineChart>
//     // <Doughnut data={transformedData} />
//     <div className="App">
//       <Line data={transformedData} />
//     </div>
//   );
// }

// // df

import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import { formatDate } from "../../../CommonComponents/customTableComponent/tableAction";

// Register the required components
ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Filler
);

export default function BasicArea({ data }) {
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // Transform data into the required format
  const transformedData = React.useMemo(() => {
    if (!data || data.length === 0) return { labels: [], datasets: [] };

    // Sort the data by date
    const sortedData = [...data].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const labels = sortedData?.map((item) =>
      formatDate(new Date(item?.date).toISOString().split("T")[0])
    );
    const quantities = sortedData.map((item) => item.quantity);

    const datasets = [
      {
        label: "Quantities",
        data: quantities,
        fill: true,
        backgroundColor: "#C4A84566",
        borderColor: "#C4A845",
      },
    ];

    return { labels, datasets };
  }, [data]);

  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // const displayOptions = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   aspectRatio: 2, // Adjust this value to change the aspect ratio
  //   resizeDelay: 100, // Delay in milliseconds
  //   plugins: {
  //     legend: {
  //       labels: {
  //         font: {
  //           size: 10,
  //         },
  //       },
  //       position: "top",
  //     },
  //   },
  //   scales: {
  //     x: {
  //       ticks: {
  //         maxRotation: 45,
  //         minRotation: 45,
  //       },
  //     },
  //   },
  // };

  const displayOptions = {
    responsive: true,
    Filler: true,
    maintainAspectRatio: false,
    aspectRatio: chartWidth < 600 ? 1 : 3, // Adjust aspect ratio for smaller screens
    plugins: {
      legend: {
        labels: {
          font: {
            size: chartWidth < 600 ? 8 : 10,
          },
        },
        position: "top",
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: chartWidth < 600 ? 8 : 10,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: chartWidth < 600 ? 8 : 10,
          },
        },
      },
    },
  };
  return (
    <div
      ref={chartRef}
      className="w-full h-full relative min-h-[300px] max-h-[600px]"
    >
      <Line data={transformedData} options={displayOptions} />
    </div>
  );
}
