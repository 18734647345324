import Account_management_stats from "./account-management-stats";
import Debt_management_stats from "./debt-management-state";
import Mortgage_management_stats from "./mortgage-management-state";
import Stock_management_stats from "./stock-management-stats";

const index = () => {
  return (
    <div className="bg-[#F4F4F4] px-3.5 sm:px-5 py-14 flex flex-col gap-10">
      <Account_management_stats />
      <Stock_management_stats />
      <Debt_management_stats />
      {/* <Mortgage_management_stats /> */}
    </div>
  );
};
export default index;
