import React, { useContext, useEffect, useRef, useState } from "react";
import { submitFine } from "../actions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "../CommonComponents/TextField";
import Dropdown from "../CommonComponents/Dropdown";
import Upload from "../CommonComponents/Upload";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import Slider from "@mui/material/Slider";
import Progressbar from "../CommonComponents/Progressbar";
import SelectItemPopUp from "../Popup/selectItemPopup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../context/authcontext";
// import { Button, Stack } from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  height: "80vh",
  bgcolor: "#FFF",
  maxWidth: "800px",
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const AccountManagementModal = ({ rowData, setOpen }) => {
  const [selectedItemOpen, setSeletecedItemOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pic, setPic] = useState(null);
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  const [accountDetails, setAccountDetails] = useState({
    amount: null,
    lab_charge: null,
    amt_received: null,
    amt_remaining: null,
    delivery_date: null,
    pay_mode: "cash",
    pay_debt_type: "credit",
    user_name: null,
    current_price: null,
    mobile_no: null,
    aadhar_no: null,
    signature_img_url: null,
    ref_name: null,
    description: null,
    item_id: null,
  });
  const clearAll = () => {
    setAccountDetails({
      amount: "",
      lab_charge: "",
      amt_received: "",
      amt_remaining: "",
      delivery_date: "",
      pay_mode: "cash",
      pay_debt_type: "credit",
      user_name: "",
      current_price: "",
      mobile_no: "",
      aadhar_no: "",
      signature_img_url: "",
      ref_name: "",
      description: "",
      item_id: "",
    });
    setPic(null);
    setSelectedItem(null);
  };

  const picUpload = (e) => {
    const [file] = e.target.files;
    // console.log("pic uploading", file);
    setPic(URL.createObjectURL(file));
    setAccountDetails({
      ...accountDetails,
      signature_img_url: file,
    });
    // setFile(file);
  };

  const handleChnage = (e) => {
    // console.log(e, "changing...");
    setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let remainingAmount = 0;
    let amt = parseFloat(accountDetails.amount);
    let lab = parseFloat(accountDetails.lab_charge);
    let recive = parseFloat(accountDetails.amt_received);
    if (amt >= 0) {
      if (amt + lab > recive && amt >= 0 && lab >= 0 && recive >= 0) {
        remainingAmount = amt + lab - recive;
        console.log(remainingAmount, "remainnign amount");
      }
    }

    setAccountDetails({ ...accountDetails, amt_remaining: remainingAmount });
  }, [
    accountDetails.amount,
    accountDetails.amt_received,
    accountDetails.lab_charge,
  ]);

  useEffect(() => {
    setAccountDetails({ ...accountDetails, item_id: selectedItem?.id });
  }, [selectedItem]);
  const handleSubmit = async () => {
    let apiLink =
      `${process.env.REACT_APP_SERVER_HOST}` +
      "api/accounts/addAccountInformation";
    if (rowData?.id) {
      apiLink =
        `${process.env.REACT_APP_SERVER_HOST}` +
        "api/accounts/updateAccountInformation";
    }
    const formData = new FormData();
    Object.keys(accountDetails).forEach((key) => {
      formData.append(key, accountDetails[key]);
    });
    formData.append("id", rowData?.id);
    // console.log(formData, "formData");
    // formData.forEach((value, key) => {
    //   console.log(key, value, "key value");
    // });
    try {
      setLoading(true);
      const addAccount = await axios.post(apiLink, formData);
      if (addAccount.data.code === 200) {
        toast.success(addAccount.data.message);
        setFetchAgain((prev) => !prev);
        clearAll();
      } else {
        toast.error(addAccount.data.message);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      // console.log(, "error in adding account management");
    }
  };

  useEffect(() => {
    console.log(rowData, "row data are comming");
    if (rowData)
      setAccountDetails({
        amount: rowData?.amount,
        lab_charge: rowData?.lab_charge,
        amt_received: rowData?.amt_received,
        // amt_remaining: rowData?.amt_remaining,
        delivery_date: rowData?.delivery_date,
        pay_mode: rowData?.pay_mode,
        pay_debt_type: rowData?.pay_debt_type,
        user_name: rowData?.user_name,
        current_price: rowData?.current_price,
        mobile_no: rowData?.mobile_no,
        aadhar_no: rowData?.aadhar_no,
        signature_img_url: rowData?.signature_img_url,
        ref_name: rowData?.ref_name,
        description: rowData?.description,
        item_id: rowData?.item_id,
        oldImagePath: rowData?.signature_img_url,
      });
    else {
      clearAll();
    }
  }, [rowData]);
  // console.log(selectedItem, "selectedItem");
  // console.log(accountDetails, "account Details");
  return (
    <Box sx={style} className="!w-[90%] lg:!w-full">
      <div className="flex flex-col items-center gap-4 sm:p-[32px] p-4">
        <h1 className="text-[18px] ">
          {rowData ? "Update Transaction" : "New Transaction"}
        </h1>
        <div className="capitalize">
          <div className="formLayout">
            <div className="flex-col modalFormDivs sm:flex-row">
              <TextField
                name="amount"
                label="Amount"
                placeholder="Rs 1000"
                type="text"
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  setAccountDetails({
                    ...accountDetails,
                    [e.target.name]: value,
                  });
                }}
                inputProps={{
                  // maxLength: 12,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
                value={accountDetails.amount}
                // onChange={handleChnage}
              />
              <TextField
                name="lab_charge"
                label="Labour Charge"
                placeholder="Rs 10000"
                type="text"
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  setAccountDetails({
                    ...accountDetails,
                    [e.target.name]: value,
                  });
                }}
                inputProps={{
                  // maxLength: 12,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
                value={accountDetails.lab_charge}
                // onChange={handleChnage}
              />
            </div>
            <div className="flex-col modalFormDivs sm:flex-row">
              <TextField
                name="amt_received"
                label="Amount Recieved"
                type="text"
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  setAccountDetails({
                    ...accountDetails,
                    [e.target.name]: value,
                  });
                }}
                inputProps={{
                  // maxLength: 12,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
                value={accountDetails.amt_received}
                // onChange={handleChnage}
              />
              <TextField
                name="amt_remaining"
                label="Remaining Amount"
                type="number"
                value={accountDetails.amt_remaining}
                onChange={handleChnage}
                editable={false}
              />
            </div>
            <div className="flex-col modalFormDivs sm:flex-row">
              <Dropdown
                name="pay_mode"
                optionarr={["cash", "Online"]}
                label="payment mode"
                value={accountDetails.pay_mode}
                handleChnage={handleChnage}
              />
              <Dropdown
                name="pay_debt_type"
                optionarr={["credit", "debit"]}
                label="Payment type"
                // value={rowData?.paymentType}
                // reference={paymentType}
                value={accountDetails.pay_debt_type}
                handleChnage={handleChnage}
              />
            </div>
            <div className="flex-col modalFormDivs sm:flex-row">
              <TextField
                label="name"
                name="user_name"
                placeholder="Raju"
                type="Text"
                // value={rowData?.name}
                // reference={name}
                value={accountDetails.user_name}
                onChange={handleChnage}
              />
              <TextField
                name="current_price"
                label="Current Price"
                placeholder="Rs 10000"
                type="text"
                onChange={(e) => {
                  const value = e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*)\./g, "$1");

                  setAccountDetails({
                    ...accountDetails,
                    [e.target.name]: value,
                  });
                }}
                inputProps={{
                  // maxLength: 12,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
                // value={rowData?.currentPrice}
                // reference={current_price}
                value={accountDetails.current_price}
                // onChange={handleChnage}
              />
            </div>
            <div className="flex-col modalFormDivs sm:flex-row">
              <TextField
                label="Mobile No."
                placeholder="Mobile no."
                type="text"
                name="mobile_no"
                value={accountDetails?.mobile_no}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  if (value.length <= 10) {
                    setAccountDetails({
                      ...accountDetails,
                      [e.target.name]: value,
                    });
                  }
                }}
                inputProps={{
                  maxLength: 12,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
              />
              <TextField
                label="Aadhar No."
                placeholder="aadhar no"
                type="text"
                name="aadhar_no"
                value={accountDetails?.aadhar_no}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, "");
                  if (value.length <= 12) {
                    setAccountDetails({
                      ...accountDetails,
                      [e.target.name]: value,
                    });
                  }
                }}
                inputProps={{
                  maxLength: 12,
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
              />
            </div>
            <div className="flex-col modalFormDivs ">
              <Upload
                label="Upload signature"
                // placeholder="7485691458"
                type="file"
                name="signature_img_url"
                // value={}
                // value={accountDetails.aadhar_no}
                handleChnage={picUpload}
              />
            </div>
            <div className="flex justify-end w-full rounded">
              {pic ? (
                <img src={pic} className="size-12" />
              ) : rowData?.signature_img_url ? (
                <img
                  className="size-10"
                  src={`http://localhost:8800/imagePath/${rowData?.signature_img_url
                    .split("/")
                    .slice(5)
                    .join("/")}`}
                  alt="logo"
                />
              ) : (
                <p>Upload Image</p>
              )}
            </div>

            <div className="modalFormDivs">
              <TextField
                label="Description"
                placeholder="Enter Description"
                type="text"
                name={"description"}
                // value={rowData?.description}
                // reference={description}
                value={accountDetails.description}
                onChange={handleChnage}
              />
              <TextField
                label="Reference Name"
                placeholder="Enter Description"
                type="text"
                name={"ref_name"}
                // value={rowData?.referenceName}
                // reference={referenceName}
                value={accountDetails.ref_name}
                onChange={handleChnage}
              />
            </div>

            <div className="modalFormDivs">
              <TextField
                label="Delivery Date"
                placeholder="Enter Date"
                type="date"
                name={"delivery_date"}
                // value={rowData?.Date}
                // reference={delivery_date}
                value={accountDetails.delivery_date}
                onChange={handleChnage}
                // value={"2034-04-23"}
              />
            </div>
            <div
              className="flex flex-col items-center gap-3 cursor-pointer sm:flex-row"
              onClick={() => {
                setSeletecedItemOpen(true);
              }}
            >
              <div className="cursor-pointer btnOutlined">
                <p>Select Items</p>
              </div>
              <p className="text-sm underline">Or buy item</p>
            </div>

            {selectedItem && (
              <div className="flex items-center justify-between w-full p-2 border ">
                <div className="size-10">
                  <img
                    className="hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                    src={`http://localhost:8800/imagePath/${selectedItem?.img_url
                      ?.split("/")
                      ?.slice(5)
                      ?.join("/")}`}
                    alt="jewellery"
                  />
                </div>
                <div>{selectedItem?.name}</div>
                <div>{selectedItem?.weight}g</div>
                <div
                  className="cursor-pointer"
                  onClick={() => setSelectedItem(null)}
                >
                  <ClearIcon />
                </div>
              </div>
            )}
            {/* <div className="flex gap-1">
              <p className="formsLabel">Pending Status</p>
              <input
                onClick={() => setPendingStatus((prev) => !prev)}
                type="checkbox"
                checked={pendingStatus}
                className=""
              />
            </div>
            {pendingStatus && (
              <div className="flex-col modalFormDivs sm:flex-row">
                <TextField
                  label="Due Date"
                  placeholder="Enter Date"
                  type="date"
                  value={rowData?.dueDate}
                />
                <TextField
                  label="Due Ammount"
                  placeholder="Enter Ammount"
                  type="number"
                  value={rowData?.dueAmmount}
                />
              </div>
            )} */}

            {/* <hr className="h-[2px] bg-[#000] opacity-20 w-full" />
            <div className="flex items-start justify-start gap-1">
              <div>
                <p className="formsLabel">Debt Payment (Udhaar)</p>
                <span className="text-[12px] text-[#999]">
                  Automatically added to Debt Management List
                </span>
              </div>

              <input
                type="checkbox"
                checked={DebtpendingStatus}
                className=""
                onChange={() => setDebtPendingStatus((prev) => !prev)}
              />
            </div>
            {DebtpendingStatus && (
              <>
                <div className="modalFormDivs items-center !justify-center flex-col sm:flex-row">
                  <TextField
                    label="Amount Paid"
                    placeholder="Rs 10000"
                    type="number"
                    value={rowData?.amountPaid}
                  />
                  <Box sx={{ maxWidth: 300 }} className="sm:!w-[300px]">
                    <Slider
                      max={rowData?.Amount}
                      disabled
                      defaultValue={rowData?.amountPaid}
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      className="!text-[#000]"
                    />
                    <p className="text-[16px] text-[#999]">
                      {(rowData?.amountPaid / rowData?.Amount) * 100}% of amount
                      is paid
                    </p>
                  </Box>
                </div>
                <div className="modalFormDivs">
                  <TextField
                    label="Interest Percentage"
                    placeholder="8%"
                    type="number"
                    value={rowData?.interestPercentage}
                  />
                </div>
                <div className="flex-col modalFormDivs sm:flex-row">
                  <TextField
                    label="Start Date"
                    placeholder="ENter date"
                    type="date"
                    value={rowData?.startDate}
                  />
                  <TextField
                    label="Debt Due Date"
                    placeholder="ENter date"
                    type="date"
                    value={rowData?.debtDueDate}
                  />
                </div>
              </>
            )} */}

            <div className="modalFormDivs">
              <button className="discardBtn" onClick={() => setOpen(false)}>
                Discard
              </button>
              <button
                className="submitBtn"
                onClick={() => {
                  handleSubmit().then(() => setOpen(false));
                }}
              >
                {rowData ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        keepMounted
        open={selectedItemOpen}
        onClose={() => setSeletecedItemOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className=""
      >
        {
          <SelectItemPopUp
            setSeletecedItemOpen={setSeletecedItemOpen}
            setSelectedItem={setSelectedItem}
          />
        }
      </Modal>
    </Box>
  );
};

export default AccountManagementModal;

{
  /* // { id: 1, name: "name", label: "Name" },
// { id: 2, name: "Date", label: "Date" },

// { id: 3, name: "Number", label: "Number" },
// { id: 4, name: "Amount", label: "Amount" },
// { id: 6, name: "labourCharge", label: "Labour Charge" },
// { id: 7, name: "paymentMode", label: "Payment Mode" },
// { id: 8, name: "paymentType", label: "Type" },
// { id: 9, name: "customerType", label: "Customer Type" },
// { id: 10, name: "signature", label: "Signature" },
// { id: 11, name: "referenceName", label: "Reference Name" },
// { id: 12, name: "description", label: "Description" },
// { id: 13, name: "selectedItem", label: "seletedItem" },
// { id: 14, name: "buyItem", label: "Buy Item" },
// { id: 15, name: "status", label: "Status" },
// { id: 16, name: "pendingDueDate", label: "Pending Due Date" },
// { id: 17, name: "debtPayment", label: "Debt payment (Udhar)" },
// { id: 18, name: "amountPaid", label: "AmountPaid" },
// { id: 19, name: "interestType", label: "Interest Type" },
// { id: 20, name: "interestPercentage", label: "Interest Percentage" },
// { id: 21, name: "startDate", label: "StartDate" },
// { id: 22, name: "debtDueDate", label: "Debt Due Date" },
// { id: 23, name: "Aadhar_No", label: "Aadhar No" },
// { id: 24, name: "dueDate", label: "Due Date" }, */
}
