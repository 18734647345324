import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Debt_management_stats = () => {
  const [debtData, setDebtData] = useState({
    totalDebt: 0,
    totalDebtNumber: 0,
    totalInterestAmt: 0,
    lastDebtEntries: [],
  });

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_HOST}` + "api/accounts/dashboarddebt"
        );
        if (response.data.code === 200) {
          const { data, lastDebtEntries } = response.data;
          setDebtData({
            totalDebt: data[0].total_debt,
            totalDebtNumber: data[0].total_debt_number,
            totalInterestAmt: data[0].total_interest_amt,
            lastDebtEntries,
          });
        } else {
          console.error("Failed to fetch debt data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };

    fetchDebtData();
  }, []);
  // const customerType = [
  //   "vendor payment",
  //   "customer",
  //   "vendor payment",
  //   "others",
  // ];

  // const paymentType = ["Credit", "Debit", "Debit", "Credit"];

  // const paymentDate = [
  //   "8 June 2024",
  //   "7 June 2024",
  //   "5 June 2024",
  //   "1 June 2024",
  // ];

  // const name = ["Sara", "Suraj", "Gourav", "Neha"];

  // const paymentMoney = ["1000", "2000", "3000", "4000"];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap justify-between gap-y-2">
        <h3 className="text-[#030303] text-lg not-italic font-semibold leading-normal text-end">
          Debt Management
        </h3>
        <Link to="debt_management">
          <div className="flex gap-2 px-2 border border-solid border-[#333] rounded">
            <h3 className="text-right text-base not-italic font-medium leading-[28.8px]">
              Detailed View
            </h3>
            <img src="/images/add-icon.svg" alt="add icon" />
          </div>
        </Link>
      </div>

      <div className="bg-[#FEFEFE] rounded-lg px-4 sm:px-8 py-6 flex gap-8 max-[844px]:flex-col">
        <div className="py-[7px] flex flex-col justify-between items-center gap-y-4 pr-8 borderNone border-solid border-[#00000033]">
          <div className="flex flex-col text-[#588B58] text-[40px] not-italic font-bold leading-[48px] items-center">
            <div className="flex gap-[6px]">
              <h2>+</h2>
              <h2>&#8377;{debtData.totalDebt}</h2>
            </div>

            <h3 className="text-[#030303] text-center text-[12px] not-italic font-medium leading-[18px]">
              Left to Take
            </h3>
          </div>

          <div className="px-4 py-2 sm:py-[11px] bg-[#F4F4F4] rounded-lg flex gap-3 md:gap-8">
            <div className="flex flex-col items-center pr-3 md:pr-8 border-r border-solid border-[#00000033] py-[6px]">
              <h3 className="text-[030303] text-center self-stretch text-lg not-italic font-semibold leading-normal">
                {debtData.totalDebtNumber}
              </h3>
              <div className="flex items-center justify-center">
                <h4 className="text-[12px] not-italic font-medium leading-[18px]">
                  Udhari
                </h4>
              </div>
            </div>
            <div className="flex flex-col items-center py-[6px]">
              <h3 className="text-[030303] text-center self-stretch text-lg not-italic font-semibold leading-normal">
                {debtData.totalInterestAmt
                  ? `₹${debtData.totalInterestAmt}`
                  : "null"}
              </h3>
              <div className="flex items-center justify-center">
                <h4 className="text-[12px] not-italic font-medium leading-[18px]">
                  Interest
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-4">
          <h3 className="self-stretch text-[#030303] text-base not-italic font-medium leading-[28.8px]">
            Last 4 Entries
          </h3>

          <div className="relative flex flex-col w-full gap-4 py-2 overflow-x-auto">
            {debtData.lastDebtEntries.length > 0
              ? debtData.lastDebtEntries.map((entry, index) => (
                  <div
                    className={`flex justify-between break-all text-[#030303] pb-4 text-sm not-italic font-normal ${
                      index === 3 ? "" : "border-b"
                    }  border-solid border-[#03030326] leading-[21px] w-fit md:w-full`}
                  >
                    <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                      {entry?.user_name}
                    </h3>
                    <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                      {entry?.amount}
                    </h3>

                    <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                      {entry?.start_date}
                    </h3>
                    <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                      {entry?.due_end_date}
                    </h3>
                    <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                      &#8377;{entry?.paid_amount ? `${entry.paid_amount}` : "0"}
                    </h3>
                    <h3 className="w-[20%] min-w-[130px] md:min-w-0">
                      &#8377;
                      {entry?.total_interest_amt
                        ? `${entry.total_interest_amt}`
                        : "0"}
                    </h3>
                  </div>
                ))
              : "No data available"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debt_management_stats;
