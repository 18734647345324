import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideDrawer = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen((prev) => (isMobile ? false : !prev));

    if (isMobile) {
      setOpen((prev) => !prev);
    }
  };

  const imageUrl = [
    "/images/home-icon.svg",
    "/images/account-icon.svg",
    "/images/stock-icon.svg",
    "/images/debt-icon.svg",
    "/images/mortage-icon.svg",
    "/images/fine-icon.svg",
    "/images/sign-out.svg",
  ];

  const routing = [
    "/",
    "/account_management",
    "/stock_management",
    "/debt_management",
    "/mortgage_management",
    "/fine_management",
    "/log_out",
  ];
  const titles = ["Home",
            "Account Management",
            "Stock Management",
            "Debt Management",
            "Mortage Management",
            "Fine Management",
            "logout"];

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        position: open ? (isMobile ? "absolute" : "static") : "",
        "& .MuiPaper-root": {
          width: open ? "260px !important" : "",
        },
        "& .css-urhxzd-MuiDrawer-docked": {
          width: open ? "260px !important" : "",
        },
        "& .MuiDrawer-paper": {
          width: open ? "" : "calc(56px + 7px) !important",
        },
      }}
    >
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
        className="!border-[0px] "
        sx={{
          "& .MuiPaper-root": {
            border: "none",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
          },
        }}
      >
        <DrawerHeader
          sx={{
            justifyContent: open ? "start" : "center",
            alignItems: "center",
            minHeight: "fit-content !important",
            padding: "0px",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              padding: "0px",
              width: "100%",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {theme.direction === "rtl" ? (
              <></>
            ) : (
              <div
                className={`flex ${
                  open ? "justify-start" : "justify-center"
                } items-start w-full`}
              >
                {open ? (
                  <div className="flex gap-[10px] py-2 items-center border-b border-[#DDD] w-full">
                    <img
                      src="/images/logo.svg"
                      alt="logo"
                      className="w-[19px] h-[29px]"
                    />
                    <h1 className="text-sm not-italic font-bold leading-normal text-[#111]">
                      HK Jewellers
                    </h1>
                  </div>
                ) : (
                  <div className="flex gap-1 py-2 ml-0.5 border-b border-[#DDD]">
                    <img src="/images/logo.svg" alt="logo" />
                  </div>
                )}
              </div>
            )}
          </IconButton>
        </DrawerHeader>

        <List
          sx={{
            paddingTop: "0px",
            paddingBottom: "0px",
            width: "100%",
          }}
        >
          <ListItem disablePadding sx={{ display: "block", width: "100%" }}>
            <ListItemButton
              sx={{
                justifyContent: open ? "end" : "center ml-1",
                padding: "0px",
                minHeight: "fit-content",
                width: "100%",
                "&:hover": {
                  backgroundColor: "transparent !important",
                },
                "&:active": {
                  backgroundColor: "transparent !important",
                },
                "&:focus": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                e=" bg-black shadow-none"
                sx={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  width: open ? "100%" : "max-content",
                  "&:hover": {
                    backgroundColor: "transparent !important",
                  },
                  "&:active": {
                    backgroundColor: "transparent !important",
                  },
                  "&:focus": {
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                {open ? (
                  <div className="flex justify-between items-center w-full">
                    <h1 className="text-base not-italic font-medium leading-normal fontRoboto">
                      Hi, User
                    </h1>
                    <div className="flex gap-4">
                      <img src="/images/notification.svg" alt="setting icon" />
                      <img
                        src="/images/menu.svg"
                        alt="menu icon"
                        className="w-8 h-8 transition-all duration-300"
                        onClick={handleDrawerOpen}
                      />
                    </div>
                  </div>
                ) : (
                  <img
                    src="/images/menu.svg"
                    alt="menu icon"
                    className="w-8 ml-2 h-8 horizontal_flip transition-all duration-300"
                    onClick={handleDrawerOpen}
                  />
                )}
              </IconButton>
            </ListItemButton>
          </ListItem>
        </List>
        <List
          sx={{
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: "flex",
            flexDirection: "column",
            gap: "11px",
          }}
        >
          {[
            "Home",
            "Account Management",
            "Stock Management",
            "Debt Management",
            "Mortage Management",
            "Fine Management",
            "logout",
          ].map((text, index) => (
            <Tooltip
            key={text}
            title={titles[index]} 
            placement="right"
            disableHoverListener={open}
            >

            <ListItem
              key={text}
              disablePadding
              sx={{
                display: "block",
              }}
            >
              <NavLink
                to={`${routing[index]}`}
                style={({ isActive }) => ({
                  backgroundColor: "#EBD37D",
                })}
                onClick={()=>setOpen(false)}
              >
                {({ isActive }) => (
                  <ListItemButton
                    sx={{
                      minHeight: "fit-content",
                      justifyContent: open ? "initial" : "start",
                      padding: "0px !important",
                      display: "flex",
                      gap: "16px",
                      paddingTop: "8px !important",
                      paddingBottom: "8px !important",
                      paddingLeft: open ? "16px !important" : "0px !important",
                      paddingRight: open ? "16px !important" : "0px !important",
                      backgroundColor: isActive
                        ? "#EBD37D !important"
                        : "transparent",
                      borderRadius: "8px",
                    }}
                    // onClick={handleDrawerOpen}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                        paddingLeft: open ? "0px !important" : "6px !important",
                        paddingRight: open
                          ? "0px !important"
                          : "6px !important",
                      }}
                    >
                      <img
                        src={imageUrl[index]}
                        alt={text}
                        style={{
                          width: "20px",
                          height: "20px",
                          fontFamily: "Montserrat",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{
                        display: open ? "block" : "none",
                        fontSize: "14px !important",
                        "& .MuiTypography-root": {
                          fontSize: "14px !important",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                        },
                      }}
                    />
                  </ListItemButton>
                )}
              </NavLink>
            </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
    // <>
    //   <div className="flex flex-col gap-4 w-[270px] h-[100dvh] bg-[#FFF] px-2 py-4">
    //     <div className="flex gap-[10px] w-full h-fit items-center py-2 border-b border-[#DDD]">
    //       <img src="/images/logo.svg" className="w-[19px] h-[29px]" />
    //       <h2 className="text-sm font-bold not-italic text-[#111]">
    //         HK JEWELLERS
    //       </h2>
    //     </div>

    //     <div className="flex flex-col gap-4">
    //       <div className="flex justify-between items-center">
    //         <h3 className="text-base not-italic font-medium leading-none">
    //           Hi, User
    //         </h3>
    //         <div className="flex gap-4 items-center">
    //           <img
    //             src="/images/setting-icon.svg"
    //             alt="setting icon"
    //             className="w-5 h-5"
    //           />
    //           <img src="/images/menu.svg" alt="menu icon" className="w-8 h-8" />
    //         </div>
    //       </div>

    //       <div className="flex flex-col gap-[11px]">
    //         <div>

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default SideDrawer;
