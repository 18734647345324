import React from "react";

const Dropdown = ({
  optionarr,
  label,
  type,
  placeholder,
  name,
  reference,
  value,
  handleChnage = () => {},
}) => {
  // console.log(optionarr);
  // console.log(value?.toLowerCase(), "value");
  // console.log(optionarr[0]?.toLowerCase(), "options");
  return (
    <div className="labelsDivs !w-full">
      <label className="formsLables">{label}</label>
      <select
        // defaultValue={value}
        value={value}
        className="formsInputs capitalize"
        type={type}
        placeholder={placeholder}
        name={name}
        ref={reference}
        onChange={handleChnage}
      >
        {optionarr.map((op, i) => {
          return (
            <option
              selected={op?.toLowerCase() === value?.toLowerCase()}
              className="capitalize"
              value={op}
              key={i}
            >
              {op}
            </option>
          );
        })}
      </select>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6.41385 9H17.5858C17.7836 9.00004 17.9769 9.05871 18.1413 9.1686C18.3057 9.27848 18.4339 9.43465 18.5095 9.61734C18.5852 9.80004 18.605 10.0011 18.5664 10.195C18.5279 10.389 18.4327 10.5671 18.2928 10.707L12.7068 16.293C12.5193 16.4805 12.265 16.5858 11.9998 16.5858C11.7347 16.5858 11.4804 16.4805 11.2928 16.293L5.70685 10.707C5.56704 10.5671 5.47183 10.389 5.43327 10.195C5.3947 10.0011 5.4145 9.80004 5.49017 9.61734C5.56584 9.43465 5.69398 9.27848 5.85839 9.1686C6.0228 9.05871 6.2161 9.00004 6.41385 9Z"
          fill="#333333"
        />
      </svg> */}
    </div>
  );
};

export default Dropdown;
