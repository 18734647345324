import React, { useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "80vh",
  bgcolor: "#FFF",
  maxWidth: 630,
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const DebtManagementPaidHistory = ({ id }) => {
  console.log("Requesting item with ID:", id);
  const [item, setItem] = React.useState([]);
  useEffect(() => {
    const getItem = async () => {
      try {
        const responseItem = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/accounts/getDebtInstallment",
          { debt_id: id }
          // {withCredentials:true}
        );
        console.log("items :", responseItem.data.data);
        setItem(responseItem.data.data);
      } catch (error) {
        console.log(error);
        setItem([]);
      }
    };
    getItem();
  }, [id]);
  console.log(item, "itemitem");

  return (
    <Box sx={style} className="!w-[90%] md:!w-full">
      <div className="flex flex-col justify-center">
        <h1 className="text-[18px] py-4 text-center">History</h1>
        <div className="flex flex-col gap-4 p-3 sm:p-6">
          <div className="flex overflow-auto flex-col px-4 sm:px-[20px] gap-4 min-h-[196px]">
            <div className="flex justify-start w-full">
              <div className="flex justify-between w-full gap-2 flex-nowrap text-nowrap">
                <div className="flex min-w-[150px] justify-between gap-28 flex-wrap gap-y-1 md:flex-nowrap">
                  <div>Date</div>
                </div>
                <div className="flex min-w-[150px] justify-between gap-28 flex-wrap gap-y-1 md:flex-nowrap">
                  <div>Interest</div>
                </div>
                <div className="flex min-w-[150px] justify-between gap-28 flex-wrap gap-y-1 md:flex-nowrap">
                  <div>Amount</div>
                </div>
              </div>
            </div>
            {item.map((data, id) => (
              <div className="flex justify-between w-full gap-2">
                {console.log(data, "data11111")}
                <div className="min-w-[150px]">
                  <div>
                    {" "}
                    {new Date(data.paid_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </div>
                  <div>
                    {new Date(data.paid_date).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })}
                  </div>
                </div>
                <div className="min-w-[150px]">{data.interest_amt}</div>
                <div className="min-w-[150px]">{data.paid_amount}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DebtManagementPaidHistory;
