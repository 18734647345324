import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authcontext";
import axios from "axios";

const Logout = () => {
  const Navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  const handleLogout = async () => {
    Navigate("/login");
    setAuth(0);
    try {
      const responseItem = await axios.post(
        `${process.env.REACT_APP_SERVER_HOST}` + "api/auth/logout"
        // {withCredentials:true}
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleNo = () => {
    Navigate("/");
  };

  return (
    <div className="flex justify-center flex-col gap-2 items-center h-screen bg-[#F4F4F4]">
      <h3 className="text-xl font-semibold leading-[28.8px]">
        Do you want to logout
      </h3>
      <div className="flex items-center justify-center gap-3">
        <button
          className="px-3 py-1.5 bg-[#EBD37D] rounded"
          onClick={handleLogout}
        >
          Yes
        </button>
        <button
          className="px-3 py-1.5 border border-[#EBD37D] rounded"
          onClick={handleNo}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default Logout;
