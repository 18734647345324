import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TickPlacementBars from "./graph/barGraph/barGraph";
import { AuthContext } from "../context/authcontext";
import axios from "axios";
import TextField from "../CommonComponents/TextField";
import { DateRangePicker } from "react-date-range";
import { Modal } from "@mui/material";

const Stock_management_stats = () => {
  const [open, setOpen] = useState(false);

  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);
  const [data, setData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectionRange, setSelectionRange] = useState({
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  });

  const dateConverter = (date) => {
    const arr = date.toString().split(" ");
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date_str =
      arr[3] +
      "-" +
      (months.indexOf(arr[1]) + 1).toString().padStart(2, "0") +
      "-" +
      arr[2];
    return date_str;
  };

  const handleSelect = (ranges) => {
    // console.log(ranges.selection, "selection date");
    // setStartDate(ranges.selection.startDate);
    // setEndDate(ranges.selection.endDate);

    // set;
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
    console.log(ranges.selection, "ranges.selection");

    setSelectionRange(ranges.selection);
  };

  const getAllDebtkApi = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_HOST}` + "api/accounts/dashboardstock",
        {
          start_date: dateConverter(selectionRange.startDate),
          end_date: dateConverter(selectionRange.endDate),
        }
      );
      console.log(response, "Stock response");
      // data = ;
      setData(response.data.data[0]);
      // setGraphData(response.data.stockGraphDetails[0]);
      // Parse graph data into JSON
      if (
        response.data.stockGraphDetails &&
        response.data.stockGraphDetails[0]
      ) {
        const graphDataString = response.data.stockGraphDetails[0].graph_data;
        try {
          const parsedData = JSON.parse(graphDataString);
          setGraphData(parsedData);
        } catch (parseError) {
          console.error("Error parsing graph data:", parseError);
          setGraphData(null);
        }
      }

      setLoading(false);
    } catch (error) {
      console.log(error, "erro in fetching Debt data");
      setLoading(false);
    }
  };

  // getAllDebtkApi();

  useEffect(() => {
    getAllDebtkApi();
  }, [selectionRange.endDate]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-wrap justify-between gap-y-2">
        <h3 className="text-[#030303] text-lg not-italic font-semibold leading-normal text-end">
          Stock Management
        </h3>
        {/* detailed view */}
        <Link to="stock_management">
          <div className="flex gap-2 px-2 border border-solid border-[#333] rounded">
            <h3 className="text-right text-base not-italic font-medium leading-[28.8px]">
              Detailed View
            </h3>
            <img src="/images/add-icon.svg" alt="add icon" />
          </div>
        </Link>
      </div>

      <div className="bg-[#FEFEFE] rounded-lg px-8 py-6 flex gap-8 max-[844px]:flex-col">
        <div className="py-[7px] flex flex-col justify-between items-center gap-y-4 pr-8 borderNone border-solid border-[#00000033]">
          <div
            className="flex-col w-full duration-100 cursor-pointer modalFormDivs sm:flex-row hover:scale-90"
            onClick={() => {
              setOpen(true);
            }}
          >
            <TextField
              name="Start Date"
              label="Start Date"
              type="date"
              editable={false}
              value={dateConverter(selectionRange.startDate)}

              // onChange={handleChnage}
            />
            <TextField
              name="End Date"
              label="End Date"
              type="date"
              editable={false}
              value={dateConverter(selectionRange.endDate)}
              // onChange={handleChnage}
            />
          </div>

          <div className="flex flex-col text-[#588B58] text-[40px] not-italic font-bold leading-[48px] items-center">
            <h2>
              {data.available_count ? `${data.available_count}` : "Not found"}
            </h2>

            <h3 className="text-[#030303] text-center text-[12px] not-italic font-medium leading-[18px]">
              Items currently available
            </h3>
          </div>

          <div className="px-4 py-2 sm:py-[11px] bg-[#F4F4F4] rounded-lg flex gap-3 md:gap-8 sm:w-[240px] justify-center items-center">
            <div className="flex flex-col items-center pr-3 md:pr-8 border-r border-solid border-[#00000033] py-[6px]">
              <h3 className="text-[030303] text-center self-stretch text-lg not-italic font-semibold leading-normal">
                {data.total_stock ? `${data.total_stock}` : "Not found"}
              </h3>
              <div className="flex items-center justify-center">
                <h4 className="text-[#588B58] text-[12px] not-italic font-medium leading-[18px]">
                  Added
                </h4>
                <img src="images/green-arrow.svg" alt="grren arrow" />
              </div>
            </div>
            <div className="flex flex-col items-center py-[6px]">
              <h3 className="text-[030303] text-center self-stretch text-lg not-italic font-semibold leading-normal">
                {data.available_count ? `${data.sold_count}` : "Not found"}
              </h3>
              <div className="flex items-center justify-center">
                <h4 className="text-[#B15151] text-[12px] not-italic font-medium leading-[18px]">
                  Sold
                </h4>
                <img src="images/red-arrow.svg" alt="grren arrow" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-4">
          <h3 className="self-stretch text-[#030303] text-base not-italic font-medium leading-[28.8px] text-left">
            Last 7 days stock sold
          </h3>
          <TickPlacementBars data={graphData} />
          {/* <div className="w-full py-2">
            {Array(4)
              .fill(0)
              .map((_, index) => {
                return (
                  <div className="flex justify-between text-[#030303] pb-4 text-sm not-italic font-normal leading-[21px] w-full">
                    <h3 className="w-[20%]">{name[index]}</h3>
                    <h3 className="w-[20%]">{paymentDate[index]}</h3>
                    <h3 className="w-[20%]">{customerType[index]}</h3>
                    {paymentType[index] === "Credit" ? (
                      <h3 className="text-[#588B58] w-[20%]">
                        {paymentType[index]}
                      </h3>
                    ) : (
                      <h3 className="text-[#B15151] w-[20%]">
                        {paymentType[index]}
                      </h3>
                    )}
                    <h3 className="w-[20%]">&#8377;{paymentMoney[index]}</h3>
                  </div>
                );
              })}
          </div> */}
        </div>
      </div>
      <Modal
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        {/* {modalComponent} */}
        <div className="flex items-center justify-center m-auto border w-fit">
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={(ranges) => handleSelect(ranges)}
          />
        </div>

        {/* <></> */}
      </Modal>
    </div>
  );
};

export default Stock_management_stats;
