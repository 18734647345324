import React from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
const Progressbar = ({ max, defaultValue }) => {
  return (
    <Box sx={{ width: "100%" }} >
      <Slider
        max={max}
        disabled
        defaultValue={defaultValue}
        aria-label="Small"
        valueLabelDisplay="auto"
        className="!text-[#000]"
      />
    </Box>
  );
};

export default Progressbar;
