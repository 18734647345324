import React, { useEffect } from "react";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "32px",
  borderRadius: "16px",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  // height: "80vh",
  bgcolor: "#FFF",
  maxWidth: 400,
  boxShadow: 24,
  p: 4,

  scrollbarWidth: "none", // For Firefox
  msOverflowStyle: "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};
const DownloadTableData = ({downloadCSV, downloadPDF}) => {

  return (
    <Box sx={style} className="!w-[90%] md:!w-full">
      <h3 className="text-center my-4">Downlaod data</h3>
      <div className="flex items-center justify-center gap-4 my-4">
        <button className="border border-[#EBD37D] py-1.5 px-3 hover:bg-[#EBD37D] transition duration-500" onClick={downloadPDF}>Pdf</button>
        <button className="border border-[#EBD37D] py-1.5 px-3 hover:bg-[#EBD37D] transition duration-500" onClick={downloadCSV}>Csv</button>
      </div>
    </Box>
  );
};

export default DownloadTableData;
