import React, { useContext, useEffect, useState } from "react";
import AccountManagementModal from "../Modals/AccountManagementModal";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CustomTableComponent from "../CommonComponents/customTableComponent/CustomTableComponent";
import { formatDate } from "../CommonComponents/customTableComponent/tableAction";
import FolderIcon from "@mui/icons-material/Folder";
import axios from "axios";
import StockManagementSoldHistory from "../Popup/StockManagementSoldHistory";
import AccountManagementSoldItem from "../Popup/AccountManagementSoldItem";
import { AuthContext } from "../context/authcontext";

// YYYY - MM - DD;
// const data = [
//   {
//     id: 1,
//     name: "Alice Johnson",
//     Date: "2024-05-24",
//     Number: "7890123456",
//     amount: 5000,
//     labourCharge: "500",
//     paymentMode: "online",
//     paymentType: "credit",
//     signature: "AliceJ",
//     referenceName: "Bob Smith",
//     description: "Monthly subscription",
//     selectedItem: 1,
//     status: false,
//     pendingDueDate: "N/A",
//     debtPaymentStatus: false,
//     amountPaid: 5000,
//     interestPercentage: 5,
//     startDate: "2024-01-01",
//     debtDueDate: "N/A",
//     aadhar_No: "456789123654",
//     dueDate: "2024-07-14",
//   },
//   {
//     id: 2,
//     name: "Bob Smith",
//     Date: "2023-11-23",
//     Number: "9876543210",
//     Amount: 1200,
//     labourCharge: "250",
//     paymentMode: "cash",
//     paymentType: "debit",
//     signature: "BobS",
//     referenceName: "Alice Johnson",
//     description: "One-time purchase",
//     selectedItem: 2,
//     status: true,
//     pendingDueDate: "2023-12-01",
//     debtPaymentStatus: true,
//     amountPaid: 600,
//     interestPercentage: 3,
//     startDate: "2023-11-23",
//     debtDueDate: "2024-11-23",
//     aadhar_No: "123456789012",
//     dueDate: "2024-06-14",
//   },
// ];

const columns = [
  { id: 1, name: "id", label: "S.No" },
  { id: 14, name: "user_name", label: "Name" },
  {
    id: 15,
    name: "entry_date",
    label: "Entry Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "entry_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },
  {
    id: 2,
    name: "delivery_date",
    label: "Delivery Date",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "delivery_date") {
          return (
            <td className={`py-2 pr-24`}>{formatDate(row[column.name])}</td>
          );
        }
      },
    },
  },

  { id: 3, name: "mobile_no", label: "Number" },
  {
    id: 4,
    name: "amount",
    label: "Amount",
    option: {
      tablebodyCustom: (row, column) => {
        console.log(row[column.name], "amount");
        if (column.name === "amount") {
          const amount = row[column.name];
          const pay_debt_type = row.pay_debt_type;

          let colorClass = "";
          let suffix = "";

          if (pay_debt_type === "credit") {
            colorClass = "text-[#588B58]";
            suffix = " Cr.";
          } else if (pay_debt_type === "debit") {
            colorClass = "text-[#B15151]";
            suffix = " Db.";
          }
          return (
            <td className={`py-2 pr-24 ${colorClass}`}>
              {`₹ ${amount}${suffix}`}
            </td>
          );
        }
      },
    },
  },
  { id: 5, name: "amt_received", label: "Amount Received" },
  { id: 6, name: "lab_charge", label: "Labour Charge" },
  { id: 25, name: "current_price", label: "Current Price" },
  { id: 7, name: "pay_mode", label: "Payment Mode" },
  { id: 8, name: "pay_debt_type", label: "Type" },
  {
    id: 10,
    name: "signature_img_url",
    label: "Signature",
    option: {
      tablebodyCustom: (row, column) => {
        if (column.name === "signature_img_url") {
          console.log(row[column.name], "image");
          return (
            <td className={`py-2 pr-10 `}>
              <div className="w-[40px]">
                <img
                  className=" hover:scale-[2] duration-100 hover:z-[1000] object-cover rounded"
                  src={`http://localhost:8800/imagePath/${row[column.name]
                    ?.split("/")
                    ?.slice(5)
                    ?.join("/")}`}
                  alt="logo"
                />
              </div>
            </td>
          );
        }
      },
    },
  },
  { id: 11, name: "ref_name", label: "Reference Name" },
  { id: 12, name: "description", label: "Description" },
  {
    id: 13,
    name: "item_id",
    label: "Seleted Item",
    option: {
      tablebodyCustom: (row, column, setOpen, setModalComponent) => {
        if (column.name === "item_id") {
          return (
            <td className={`py-2 pr-24 relative`} value={row[column.name]}>
              <div className="cursor-pointer w-fit hvr-buzz">
                <FolderIcon
                  className=""
                  // onClick={() => getItem(row[column.name])}
                  onClick={() => {
                    setOpen(true);
                    setModalComponent(
                      <AccountManagementSoldItem id={row[column.name]} />
                    );
                  }}
                />

                {/* {isActive && (
                  <div className="bg-white w-max gap-2 items-center !z-50 top-[100%] p-4 left-[-100px] !absolute border flex">
                    <img
                      src="https://identity.getpostman.com/images/logo-postman.svg"
                      alt="Item image"
                    />
                    <h3>{ 'Item Name'}</h3>
                    <h3>Item Weights</h3>
                  </div>
                )} */}
              </div>
              {/* {isActive === row[column.name] && (
                <div className="flex gap-4 absolute bg-white border shadow-xl bottom-[-6px] m-2 p-2 right-[100%]">
                  {activeItem && activeItem.id === row[column.name] ? (
                    <img src={`${activeItem.img_url}`} alt="Item image" />
                  ) : (
                    <img src="" alt="Item image" />
                  )}{" "}
                  <h3>
                    {activeItem && activeItem.id === row[column.name]
                      ? activeItem.name
                      : "Item name"}
                  </h3>
                  <h3>
                    {activeItem && activeItem.id === row[column.name]
                      ? activeItem.weight
                      : "Item name"}
                  </h3>
                </div>
              )} */}
            </td>
          );
        }
      },
    },
  },

  // { id: 15, name: "status", label: "Status" },
  // { id: 16, name: "pendingDueDate", label: "Pending Due Date" },
  // { id: 17, name: "debtPayment", label: "Debt payment (Udhar)" },
  // { id: 18, name: "amountPaid", label: "AmountPaid" },
  // { id: 20, name: "interestPercentage", label: "Interest Percentage" },
  // { id: 21, name: "startDate", label: "StartDate" },
  // { id: 22, name: "debtDueDate", label: "Debt Due Date" },
  // { id: 23, name: "aadhar_No", label: "Aadhar No" },
  // { id: 24, name: "dueDate", label: "Due Date" },
];

const AccountManagement = () => {
  const [pageNo, setPageNo] = useState(1);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const { fetchAgain, setFetchAgain, loading, setLoading } =
    useContext(AuthContext);

  useEffect(() => {
    // console.log(pageNo, "pageNo");
    const controller = new AbortController();
    const getAllAccountsApi = async () => {
      // console.log("account response working");
      try {
        setLoading(true);
        // console.log("try block account response working");

        // await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log("Loading started...");
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_HOST}` +
            "api/accounts/getAccountInformation",
          {
            page: pageNo,
            start_date: dateFrom,
            end_date: dateTo,
          }
        );
        // console.log(response, "account response");

        if (response.data.code === 200) {
          setData(response.data.data);
        } else {
          setData([]);
          pageNo > 1
            ? setPageNo((prev) => prev - 1)
            : setPageNo((prev) => prev);
        }
        // console.log(" accounts ending--");

        setLoading(false);
      } catch (error) {
        console.log(error, "error in fetching account data");

        setLoading(false);
      }
    };

    getAllAccountsApi();

    return () => {
      controller.abort();
    };
  }, [fetchAgain, pageNo, dateTo]);

  return (
    <div id="container">
      <CustomTableComponent
        key={updateTrigger}
        title="Account Management"
        data={data}
        columns={columns}
        search={true}
        dateFilter={true}
        download={true}
        setPageNo={setPageNo}
        pageNo={pageNo}
        PopUp={AccountManagementSoldItem}
        // columnShow={4}
        Module={AccountManagementModal}
        activeItem={activeItem}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />
    </div>
  );
};

export default AccountManagement;
// option: {
//   sort: false;
// }
// gh
// sdsdf
